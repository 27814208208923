<template>
	<div class="settings-content mission-vision-page">
		<template v-if="soft_organigram.is_active">
			<div class="row-space-tbf" v-if="loaded">
				<div class="space-left"></div>
				<div class="content form-group">
					<div class="title-company">
						<div class="name">{{ company.name }}</div>
						<div class="logo">
							<img :src="company.avatar" v-if="company.avatar" />
						</div>
					</div>

					<div class="form">
						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">{{ $t("company.target") }}*</label>
								<div v-if="$v.company.target.$error" class="error-msg">{{ $t("validator.required") }}</div>
								<div v-if="errorsBe.target" class="error-msg">{{ errorsBe.target.join(" | ") }}</div>
							</div>
							<div class="input-box" v-bind:class="{ has_error: $v.company.target.$error }">
								<textarea v-model="company.target"></textarea>
							</div>
						</div>
						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">{{ $t("company.mission") }}*</label>
								<div v-if="$v.company.mission.$error" class="error-msg">{{ $t("validator.required") }}</div>
								<div v-if="errorsBe.mission" class="error-msg">{{ errorsBe.mission.join(" | ") }}</div>
							</div>
							<div class="input-box" v-bind:class="{ has_error: $v.company.mission.$error }">
								<quill-editor class="editor-tbf h-300" v-model="company.mission" :options="editorOption" />
							</div>
						</div>
						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">{{ $t("company.company_values") }}</label>
							</div>
							<div class="input-box">
								<quill-editor class="editor-tbf h-300" v-model="company.company_values" :options="editorOptionCV" />
							</div>
						</div>
						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">{{ $t("company.field_of_activity") }}</label>
							</div>
							<div class="input-box">
								<quill-editor class="editor-tbf h-300" v-model="company.field_of_activity" :options="editorOptionFA" />
							</div>
						</div>
						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">{{ $t("company.successes_results") }}</label>
							</div>
							<div class="input-box">
								<quill-editor class="editor-tbf h-300" v-model="company.successes" :options="editorOptionRR" />
							</div>
						</div>
					</div>

					<div class="form-submit">
						<div class="error-msg" v-show="error_message != ''">{{ error_message }}</div>
						<button class="btn-tbf grey" @click="getCompanyData">
							<span class="text">{{ $t("general.cancel") }}</span>
						</button>
						<button id="buttonCreate" class="btn-tbf blue" @click="saveAction">
							<div class="loader"></div>
							<span class="text">{{ $t("general.update") }}</span>
						</button>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
			<loader-page-mission-vision v-else />
		</template>
		<template v-else>
			<div class="row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<not-module class="with-border-top" />
				</div>
				<div class="space-right"></div>
			</div>
		</template>
	</div>
</template>

<script>
import NotModule from "@/components/General/NotModule";
import { required } from "vuelidate/lib/validators";
import Quill from "quill";
import LoaderPageMissionVision from "../../PagesLoaders/MissionVision";
import ImageUploader from "quill-image-uploader/src/quill.imageUploader.js";

Quill.register("modules/imageUploader", ImageUploader);

export default {
	components: {
		LoaderPageMissionVision,
		NotModule,
	},
	data() {
		return {
			loaded: false,
			company: {},
			error_message: "",
			errorsBe: {},
			editorOption: {
				modules: {
					toolbar: [
						[{ header: [1, 2, 3, false] }],
						["bold", "italic", "underline", "strike"],
						["blockquote", "code-block"],
						[{ list: "ordered" }, { list: "bullet" }, { align: [] }],
						[{ color: [] }, { background: [] }],
						["link", "image", "video"],
						["clean"],
					],
					imageUploader: {
						upload: (file) => {
							return new Promise((resolve, reject) => {
								let objData = new FormData();
								objData.append("file", file);
								objData.append("type", "brand");

								axios.post(`/instances/${this.$auth.user().instance.id}}/upload-image`, objData).then(({ data }) => {
									resolve(data.file_path);
								});
							});
						},
					},
				},
			},
			editorOptionFA: {
				placeholder: this.$t("company.placeholder_FA"),
				modules: {
					toolbar: [
						[{ header: [1, 2, 3, false] }],
						["bold", "italic", "underline", "strike"],
						["blockquote", "code-block"],
						[{ list: "ordered" }, { list: "bullet" }, { align: [] }],
						[{ color: [] }, { background: [] }],
						["link", "image", "video"],
						["clean"],
					],
					imageUploader: {
						upload: (file) => {
							return new Promise((resolve, reject) => {
								let objData = new FormData();
								objData.append("file", file);
								objData.append("type", "brand");

								axios.post(`/instances/${this.$auth.user().instance.id}}/upload-image`, objData).then(({ data }) => {
									resolve(data.file_path);
								});
							});
						},
					},
				},
			},
			editorOptionRR: {
				placeholder: this.$t("company.placeholder_RR"),
				modules: {
					toolbar: [
						[{ header: [1, 2, 3, false] }],
						["bold", "italic", "underline", "strike"],
						["blockquote", "code-block"],
						[{ list: "ordered" }, { list: "bullet" }, { align: [] }],
						[{ color: [] }, { background: [] }],
						["link", "image", "video"],
						["clean"],
					],
					imageUploader: {
						upload: (file) => {
							return new Promise((resolve, reject) => {
								let objData = new FormData();
								objData.append("file", file);
								objData.append("type", "brand");

								axios.post(`/instances/${this.$auth.user().instance.id}}/upload-image`, objData).then(({ data }) => {
									resolve(data.file_path);
								});
							});
						},
					},
				},
			},
			editorOptionCV: {
				placeholder: this.$t("company.placeholder_CV"),
				modules: {
					toolbar: [
						[{ header: [1, 2, 3, false] }],
						["bold", "italic", "underline", "strike"],
						["blockquote", "code-block"],
						[{ list: "ordered" }, { list: "bullet" }, { align: [] }],
						[{ color: [] }, { background: [] }],
						["link", "image", "video"],
						["clean"],
					],
					imageUploader: {
						upload: (file) => {
							return new Promise((resolve, reject) => {
								let objData = new FormData();
								objData.append("file", file);
								objData.append("type", "brand");

								axios.post(`/instances/${this.$auth.user().instance.id}}/upload-image`, objData).then(({ data }) => {
									resolve(data.file_path);
								});
							});
						},
					},
				},
			},
		};
	},
	computed: {
		editor() {
			return this.$refs.myQuillEditor.quill;
		},
		soft_organigram() {
			return this.$store.getters["applications/getApplication"]("Organigrama");
		},
	},
	async mounted() {
		setTimeout(() => {
			var title = this.$t("settings-navbar.company");
			this.$root.$emit("navbar_title", title);
		}, 0);

		if (this.soft_organigram.is_active) {
			await this.getCompanyData();
		}
	},
	validations: {
		company: {
			target: { required },
			mission: { required },
		},
	},
	methods: {
		async getCompanyData() {
			await axios
				.get(`/brand/${this.$auth.user().master_instance_id}/show`)
				.then(({ data }) => {
					this.company = data.data;
				})
				.finally(() => {
					this.loaded = true;
				});
		},
		saveAction() {
			var buttonName = `buttonCreate`;
			var btnSubmit = document.getElementById(buttonName);
			var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`);
			var btnSubmitText = document.querySelector(`#${buttonName} .text`);
			btnSubmit.disabled = true;
			btnSubmit.classList.add("loading");
			btnSubmitLoader.classList.add("onProgress");
			btnSubmitText.innerHTML = this.$t("btn-submit.loading");
			this.error_message = "";

			this.$v.$touch();

			if (!this.$v.$invalid) {
				axios
					.post(`brand/${this.$auth.user().instance.id}/update`, {
						name: this.company.name,
						mission: this.company.mission,
						target: this.company.target,
						field_of_activity: this.company.field_of_activity,
						successes: this.company.successes,
						company_values: this.company.company_values,
					})
					.then(({ data }) => {
						btnSubmitLoader.classList.add("finish");
						setTimeout(() => {
							btnSubmitText.innerHTML = this.$t("btn-submit.success");
							btnSubmit.classList.add("completed");
							btnSubmitLoader.classList.remove("onProgress", "finish");
							btnSubmit.classList.remove("loading");
							this.$v.$reset();
							setTimeout(() => {
								btnSubmit.classList.remove("completed");
								btnSubmitText.innerHTML = this.$t("general.update");
							}, 1000);
						}, 300);
					})
					.catch((error) => {
						btnSubmitLoader.classList.add("finish");
						this.errorsBe = error.response.data.errors;
						setTimeout(() => {
							btnSubmitText.innerHTML = this.$t("btn-submit.error");
							btnSubmit.classList.add("error");
							btnSubmitLoader.classList.remove("onProgress", "finish");
							btnSubmit.classList.remove("loading");
							setTimeout(() => {
								btnSubmit.classList.remove("error");
								btnSubmitText.innerHTML = this.$t("general.update");
								if (error.response.status == 500) {
									this.error_message = this.$t("error.500");
								}
							}, 1000);
						}, 300);
					})
					.finally(() => {
						btnSubmit.disabled = false;
					});
			} else {
				btnSubmitLoader.classList.add("finish");
				setTimeout(() => {
					btnSubmitText.innerHTML = this.$t("btn-submit.error");
					btnSubmit.classList.add("error");
					btnSubmitLoader.classList.remove("onProgress", "finish");
					btnSubmit.classList.remove("loading");
					setTimeout(() => {
						btnSubmit.classList.remove("error");
						btnSubmitText.innerHTML = this.$t("general.update");
						btnSubmit.disabled = false;
					}, 1000);
				}, 300);
			}
		},
	},
};
</script>
